body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="checkbox"] {
  height: 20px;
  width: 20px;
}

input[type="checkbox"] ~ span {
  margin-left: 10px;
}

.rot180 {
  transform: rotate(180deg);
}

.post {
  border-left: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
  padding-left: 0.5rem;
}

.post span {
  font-size: 0.75rem;
}

.post p {
  margin-bottom: 0;
}

@keyframes alertFade {
  from {
    background: rgba(255, 255, 126, 1);
  }
  to {
    background: rgba(255, 255, 126, 0.05);
  }
}

.new {
  animation-name: alertFade;
  animation-duration: 45s;
}

@media (min-width: 768px) {
  /* .tray {
    background-color: #ffffff;
    position: sticky;
    top: 0;
    right: 0;
  } */

  .header {
    background-color: #ffffff;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
}

.reply {
  font-size: small;
}

.reply.btn {
  padding: 0;
}
